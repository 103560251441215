import React from "react";
import JumpingTitle from "../JumpingTitle";
import './yourProjectsSection.css';

const YourProjectsSection = () => {
  return (
    <section className="section" id="your-projects">

    <JumpingTitle forId="your-projects">
      Your Projects?
    </JumpingTitle>

      <div className="card">
        <p className="paragraph">
          I do some freelance, so constantly looking for new projects. I can create a whole web app (complex online products, web services, dashboard with integrations etc.) from scratch to production release for every device.
        </p>
        <p className="paragraph">
          I cooperate with recommended UI/UX designers and other programmers to develop your app better and quicker. I keep all good communication and development practices, so let's contact me below!
        </p>
      </div>
    </section>
  )
}

export default YourProjectsSection;
