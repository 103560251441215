import React from "react";
import './myProjectsSection.css';
import { StaticImage } from 'gatsby-plugin-image';
import { ReactComponent as QuestionMarkIcon} from '../../images/help.svg';
import JumpingTitle from "../JumpingTitle";

const ListCard = ({ title, ImageComponent, children}) => (
  <div className="listCard">
    {ImageComponent}
    <div className="listCard__content">
      <h1 className="listCard__title">
        {title}
      </h1>

      <div className="listCard__description">
        {children}
      </div>
    </div>
  </div>
);

const MyProjectsSection = () => {
  return (
    <section className="section" id="my-projects">
      <JumpingTitle forId="my-projects">
        My Projects
      </JumpingTitle>

      <ListCard
        title="AutoSnap"
        ImageComponent={
          <a href="https://autosnap.pl/" target="_blank">
            <StaticImage
              src="../../images/autosnap-icon.png"
              width={300}
              height={300}
              alt="image"
              className="listCard__image"
            />
          </a>
        }
      >
        <p className="paragraph">
          Tool for car repair shops to make a photo documentation of fixing cars. A car repair shop gains credibility and a client can see how the car was repaired and can sell their car for more.
        </p>
        <p className="paragraph">
          The project is still in the MVP stage, but it is willing to take off soon.
        </p>
      </ListCard>

      <ListCard
        title="Ikonate"
        ImageComponent={
          <a href="https://ikonate.com/" target="_blank">
            <StaticImage
              src="../../images/ikonate-mockup.jpg"
              width={300}
              height={300}
              alt="image"
              className="listCard__image"
            />
          </a>
        }
      >
        <p className="paragraph">
          The application helps up to 300 people every day to find and custom SVG icons with a proper thickness, size and colour.
        </p>
        <p className="paragraph">
          The application is created with Node.js and REACT. It allows to download icons in zip in vary formats.
        </p>
      </ListCard>

      <ListCard
        title="Speedly"
        ImageComponent={
          <a href="https://speedly.netlify.app/" target="_blank">
            <StaticImage
              src="../../images/speedly-mockup.jpg"
              width={300}
              height={300}
              alt="image"
              className="listCard__image"
            />
          </a>
        }
      >
        <p className="paragraph">
          An old race game uses Node.js, WebSockets, Pixi.js To the game is adapted to adding the new Maps with Tiled Map Editor.
        </p>
        <p className="paragraph">
          Built just for learning and fun! Control: w, s, a, d, space.
        </p>
        <p className="paragraph">
          Created in 2018.
        </p>
      </ListCard>
    </section>
  )
}

export default MyProjectsSection;
