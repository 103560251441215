import React from "react";
import './jumpingIcons.css';

const TechIcon = ({ name, src, alt }) => (
  <div className="techIcon">
    <img src={src} alt={alt}></img>
    <span>{name}</span>
  </div>
);

const JumpingIcons = ({ iconsSet = []}) => (
  <div className="iconsContainer">
    {
      iconsSet.map(({ name, src, alt }) => (<TechIcon name={name} src={src} alt={alt} key={alt}/>))
    }
  </div>
)

export default JumpingIcons;
