
import * as React from "react";
import PropTypes from "prop-types";
// import Seo from "./Seo";

import "./reset.css";
import "./pageWrapper.css";

const PageWrapper = ({ children }) => {
  return (
    <>
      {/* <Seo title="Home" /> */}
      
      <main className="container">
        {children}
      </main>
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper;
