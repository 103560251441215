import React, { useState } from "react";
import JumpingTitle from "../JumpingTitle";
import './contactSection.css';

const contactFormUrl = 'https://getform.io/f/30d048c0-2962-4355-95c5-1da341b9bb1f';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ContactSection = () => {
  const [formState, setFormState] = useState({});
  const [error, setError] = useState();
  const [success, setIsSuccess] = useState();

  const onFormChange = ({ target }) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
    })
  };

  const getErrorMessages = (state) => {
    if (!state.name || !state.email || !state.message) {
      return 'Fill all inputs to send.';
    }

    const isEmailValid = validateEmail(state.email);
    if (!isEmailValid) {
      return 'Check your email input';
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();

    const errorMessage = getErrorMessages(formState);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setError();

    const formData = new FormData();
    formData.append('name', formState.name);
    formData.append('email', formState.email);
    formData.append('message', formState.message);
    
    fetch(contactFormUrl, { method: 'POST', body: formData })
      .then(({ ok }) => {
        if (ok) {
          setIsSuccess(true);
          setError();
          return;
        }

        setError('Oops! The request failed! Try to send again or use email from the footer.')
      });
  }

  return (
    <section className="section" id="contact">
      <JumpingTitle forId="contact">
        Contact
      </JumpingTitle>

      <div className="card">
        <form>
          <label className="form__labelContainer">
            <span className="form__labelText">Your name:</span>
            <input className="form__textInput" type="text" name="name" onChange={onFormChange} />
          </label>
          <label className="form__labelContainer">
            <span className="form__labelText">Your email:</span>
            <input className="form__textInput" type="email" name="email" onChange={onFormChange}/>
          </label>
          <label className="form__labelContainer">
            <span className="form__labelText">Your message:</span>
            <textarea className="form__textArea" type="text" name="message" onChange={onFormChange}/>
          </label>

          <div className="sendButtonContainer">
            <button type="submit" className="sendFormButton" onClick={sendMessage}>
              Send
            </button> 

            <span className="info info--error">
              {!!error && error}
            </span>

            <span className="info info--success">
              {success && 'Success! I will respond you ASAP!'}
            </span>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ContactSection;
