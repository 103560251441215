import React from "react"
import { Helmet } from "react-helmet"

const LANG = 'en';
const DESCRIPTION = 'I am building web applications as a fullstack developer. But I am not only dev.';
const TITLE = 'Marcin Zaremski';

const isProduction = process.env.NODE_ENV === 'production';

const mouseFlowConfig = `
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/71437b78-cfff-4d97-b600-380c315bc892.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`;


const Seo = () => (
  <Helmet
    htmlAttributes={{
      lang: LANG,
    }}
    title={TITLE}
    meta={[
      {
        name: `description`,
        content: DESCRIPTION,
      },
      {
        property: `og:title`,
        content:  TITLE,
      },
      {
        property: `og:type`,
        content: `website`,
      },
    ]
  }
  >
    <script>
      {isProduction && mouseFlowConfig}
    </script>
  </Helmet>
);

export default Seo
