import React from "react";
import './menu.css';

const Menu = () => (
  <ul className="menu">
    <li className="menu__element">
      <a href="#about">
        About
      </a>
    </li>
    <li className="menu__element">
      <a href="#my-projects">
        My Projects
      </a>
    </li>
    <li className="menu__element">
      <a href="#your-projects">
        Your Projects? 💖
      </a>
    </li>
    <li className="menu__element">
      <a href="#contact">
        Contact
      </a>
    </li>
  </ul>
)

export default Menu;
