import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import './aboutSection.css';
import jsIcon from '../../images/js.svg';
import tsIcon from '../../images/ts.svg';
import nodeIcon from '../../images/node.svg';
import postgreSqlIcon from '../../images/postgresql.svg';
import reactIcon from '../../images/react.svg';
import JumpingIcons from "../JumpingIcons";
import JumpingTitle from "../JumpingTitle";

const techIconSetData = [
  { name: "JavaScript", src: jsIcon, alt: "js icon" },
  { name: "TypeScript", src: tsIcon, alt: "ts icon" },
  { name: "React", src: reactIcon, alt: "react icon" },
  { name: "Node.js", src: nodeIcon, alt: "node.js icon" },
  { name: "Postgresql", src: postgreSqlIcon, alt: "postgresql icon" },
]

const BiggerEmoji = ({children}) => <span style={{ fontSize: '1.5em' }}>{children}</span>

const AboutSection = () => {
  return (
    <section className="section" id="about">
      <JumpingTitle forId="about">
        About
      </JumpingTitle>

      <div className="card">
        <div className="card__content">
          <p className="paragraph">
            I would say, I am not only software developer. Currently I am doing some freelance and hoping to start some entrepreneurial projects. <br/>
          </p>
          <p className="paragraph">
            Let me know if only you need any help with your project/startup/application! <br/>
            <a href="#your-projects" className="inlineLink"><BiggerEmoji>🚀</BiggerEmoji> Click for more! <BiggerEmoji>🚀</BiggerEmoji></a>
          </p>
        </div>
      </div>

      <div className="card">
        <h1 className="card__title">
          Experience
        </h1>

        <div className="card__content card__content--experience">
          <div>
            <p className="paragraph">
              At ucreate (Founder and Lightning) I have gained experience in startup environment. I was working in agile teams, building product from scratch and leading from time to time.<br />
            </p>
            <p className="paragraph">
              At G2A I have worked with much larger scale. I was developing and maintaining mainly front-end side of application.
            </p>
            <p className="paragraph">
              <a href="/Marcin_Zaremski_Fullstack_CV.pdf" target="_blank" className="inlineLink"><BiggerEmoji>📜</BiggerEmoji> Grab my CV <BiggerEmoji>📜</BiggerEmoji></a>
            </p>
          </div>

          <div className="card__experienceIcons">
            <StaticImage
              src="../../images/f&l.png"
              width={100}
              alt="founder and lightning company logo"
            />
            <StaticImage
              src="../../images/g2a.png"
              width={100}
              alt="founder and lightning company logo"
            />
          </div>
        </div>
      </div>

      <div className="card">
        <h1 className="card__title">
          Technologies
        </h1>

        <div className="card__content">
          <JumpingIcons iconsSet={techIconSetData}/>
        </div>
      </div>
    </section>
  )
}

export default AboutSection;
