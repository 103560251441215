import * as React from "react";
import './general.css';
import PageWrapper from "../components/PageWrapper";
import Menu from '../components/Menu';
import AboutSection from '../components/AboutSection';
import MyProjectsSection from '../components/MyProjectsSection';
import YourProjectsSection from '../components/YourProjectsSection';
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import Seo from "../components/Helmet";
import LocationContext from "../utils/LocationContext";

const LeftColumn = () => (
  <div className="leftColumn">
    <div className="leftColumnContent">
      <header>
        <h1 className="helloHeader">Hello, I'm <br/> Marcin Zaremski.</h1>
        <span className="helloSubtitle">Not only dev.</span>
      </header>

      <Menu/>
    </div>
  </div>
);

const RightColumn = () => (
  <div className="rightColumn">
    <AboutSection/>
    <MyProjectsSection/>
    <YourProjectsSection/>
    <ContactSection/>
  </div>
)

const IndexPage = ({ location }) => (
  <LocationContext.Provider value={location}>
    <Seo/>
    <PageWrapper>
      <LeftColumn/>
      <RightColumn/>
      <Footer />
    </PageWrapper>
  </LocationContext.Provider>
);

export default IndexPage;
