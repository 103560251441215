import React, { useRef, useEffect } from "react";
import './footer.css';
import { ReactComponent as LinkedinIcon } from '../../images/linkedin.svg';
import { ReactComponent as Px500Icon } from '../../images/500px.svg';

const onEmailClick = () => navigator.clipboard.writeText('marcinzara@gmail.com');

const Footer = () => {
  const emailRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      emailRef.current.innerHTML = '@';
    }, 100);
  }, []);

  return (
    <div className="footerWrapper">
      <div className="footer">
        <a
         href="https://www.linkedin.com/in/marcin-zaremski-8b3b4714b/"
         className="footer__icon"
          target="_blank"
        >
          <LinkedinIcon />
        </a>

        <a
          href="https://500px.com/p/mzaremski"
          target="_blank"
          style={{marginLeft: '7px'}}
          className="footer__icon pxIcon"
        >
          <Px500Icon />
        </a>

        <button className="emailCopyButton" onClick={onEmailClick}>
          <span className="emailText" ref={emailRef}></span>
        </button>
      </div>
    </div>
  )
};

export default Footer;
