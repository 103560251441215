import React, { useState, useEffect, useContext } from 'react';
import './jumpingTitle.css';
import LocationContext from '../../utils/LocationContext';

const JumpingTitle = ({ children, forId }) => {
  const [shouldRunAnimation, setShouldRunAnimation] = useState(false);
  const location = useContext(LocationContext);

  useEffect(() => {
    if (!location?.hash?.includes(forId)) return;

    setTimeout(() => setShouldRunAnimation(true), 500);
    setTimeout(() => setShouldRunAnimation(false), 750);
  }, [location.hash]);
  return (
    <h1 className="section__title">
      <span className={`${shouldRunAnimation && 'runAnimation'} animatedTitle`}>
        {children}
      </span>
    </h1>
  )
}

export default JumpingTitle;
